export default `<style>
@font-face {
    font-family: "Bookman Old Style";src: local("Bookman Old Style"), url("fonts/bookold.woff") format("woff");}.font-bookman {font-family: "Bookman Old Style", sans-serif;font-size:12px;
} 
@font-face {
    font-family: "Verdanai";
    src: url("assets/fonts/VERDANAI.TTF");
}
.font-verdanai{
    font-family: "Verdanai";
}
.new_line {
    page-break-after: always;
}
html,body {
    font-family: Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif !important;
    font-size:11px;
}
table thead, table thead tr, table thead tr th{
    font-weight:normal;
}
table thead tr th.title-border-dashed-both{
    border-top:1px dotted black;
    border-bottom:1px dotted black;
    font-weight:bold;
}
.bg-green-light{
    background-color: #cfffcf !important;
}
table thead tr.fw-bold th, th.fw-bold, table tbody tr.fw-bold td,td.fw-bold{
    font-weight:bold !important;
}
table thead tr.fw-500 th, th.fw-500, table tbody tr.fw-500 td,td.fw-500{
    font-weight:500 !important;
}
table thead tr.text-uppercase th, table tbody tr.text-uppercase td{
    text-transform:uppercase;
}
thead.fs-9 tr th,tbody.fs-9 tr td{
    font-size:9px !important;
}
thead.fs-10 tr th,tbody.fs-10 tr td{
    font-size:10px !important;
}
thead.fs-11 tr th,tbody.fs-11 tr td{
    font-size:11px !important;
}
thead.fs-12 tr th,tbody.fs-12 tr td{
    font-size:12px !important;
}
.bg-silver-50 {
    background-color: #f7f8f8 !important;
}
.bg-red-100 {
    background-color: #f8c9c5 !important;
  }

.bg-red-50, tr.bg-red-50 td {
    background-color: #fceae8 !important;
}
.font-family-times{
    font-family: "Times New Roman", Times, serif;
}
.fs-13{
    font-size:13px;
}
.fs-14{
    font-size:14px;
}
.fs-10{
    font-size:10px !important;
}
.fs-9{
    font-size:9px !important;
}
th.vertical-text{
  transform: rotate(-90deg);
  transform-origin: left bottom;
  white-space: nowrap;
}
.hide-on-print{
    display:none !important;
}
</style>`;
