import React, { useState, useEffect, useContext, useCallback } from "react";
import $ from "jquery";
import { useHistory, withRouter, Link } from "react-router-dom";

import { Row, Col, Card, Button, Form, Alert } from "react-bootstrap";
import {
  Modal,
} from "antd";
import toast from "react-hot-toast";
import { liststDepartments } from "../../../models/hr";
import { liststGateWays, listCourses } from "../../../models/hr";
import PsContext from "../../../context";
import { capitalizeFirst, CardFixedTop, upperCase,appTittle } from "../../../utils";
import { Spin, Checkbox, Select } from "antd";
import axios from "axios";
import { ServiceUrl } from "../../../utils/serviceUrl";
import { COURSE_TYPE_SORT_ORDER } from "../../../utils/data";
import ModuleAccess from "../../../context/moduleAccess";
import {
  baseUrl
} from "../../../utils";
import { VENDOR_LOGO } from "../../../utils/data";

const EventRegistration = (props) => {
  const context = useContext(PsContext);
  const [stdepartments, setstDepartments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [gateway, setGateway] = useState([]);
  const [showFileManager, setShowFileManager] = useState(false);
  const [courses, setCourses] = useState([]);
  const [deptVal, setDeptVal] = useState();
  const [specifications, setSpecifications] = useState([]);
  const [typeOfInstitution, setTypeOfInstitution] = useState("");
  const [category, setcategory] = useState([]);
  const [subcategory, setsubcategory] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [registerId, setRegisterid] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [template, setTemplate] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    loadCategory();
    loadSubCategory();
  }, []);

  useEffect(() => {
    getTemplate();
  }, []);

  const handleRemindersChange = (e) => {
    setDeptVal(e);
  };

  const getTemplate = () => {
    setLoader(true);
    axios
      .post(ServiceUrl.IFORM.LIST_STUDENT_TEMPLATE)
      .then((res) => {
        if (res.data.status === "1") {
          setTemplate(res.data.data);
        } else {
          toast.error(res.data.message || "Error");
        }
        setLoader(false);
      })
      .catch((error) => {
        toast.error("Failed to fetch templates");
        setLoader(false);
      });
  };

  const handleAddSpecification = () => {
    setSpecifications((prev) => [
      ...prev,
      { key: "", value: "", dob: "", class: "", competition: "", category: "", sub_category: "" }
    ]);

  };
  const handleSpecificationChange = (index, field, value) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications[index][field] = value;

    if (field === "category") {
      const selectedCategoryId = value;

      const filteredSubcategories = subcategory.filter(
        (item) => item.category_id === selectedCategoryId
      );

      updatedSpecifications[index].subcategories = filteredSubcategories;

      if (
        !filteredSubcategories.some(
          (sub) => sub.id === updatedSpecifications[index].subCategory
        )
      ) {
        updatedSpecifications[index].subCategory = "";
      }
    }

    setSpecifications(updatedSpecifications);
  };
  const handleRemoveSpecification = (index) => {
    setSpecifications((prev) => prev.filter((_, i) => i !== index));
  };

  const loadCategory = () => {

    setLoader(true);
    axios.post(ServiceUrl.IFORM.MEMBERCATEGORY).then((res) => {
      if (res["data"].status == "1") {
        setcategory(res["data"].data);
      } else {
        toast.error(res["data"].message || "Error");
      }
      setLoader(false);
    });
  };

  const loadSubCategory = () => {

    setLoader(true);
    axios.post(ServiceUrl.IFORM.MEMBERSUBCATEGORY).then((res) => {
      if (res["data"].status == "1") {
        setsubcategory(res["data"].data);
      } else {
        toast.error(res["data"].message || "Error");
      }
      setLoader(false);
    });
  };


  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoader(true);
    const formData = new FormData(form);
    specifications.forEach((spec, index) => {
      formData.append(`specifications[${index}][key]`, spec.key);
      formData.append(`specifications[${index}][value]`, spec.value);
      formData.append(`specifications[${index}][dob]`, spec.dob);
      formData.append(`specifications[${index}][class]`, spec.class);
      formData.append(`specifications[${index}][competition]`, spec.competition);
      formData.append(`specifications[${index}][category]`, spec.category);
      formData.append(`specifications[${index}][sub_category]`, spec.sub_category);


    });
    axios
      .post(ServiceUrl.IFORM.SAVE_MEMBER_REGISTRATION, formData)
      .then((res) => {
        if (res["data"].status == "1") {
          document.getElementById("frm_saveCourse").reset();

          toast.success(res["data"].message || "Success");
          setEditorContent("");
          setRegisterid(res["data"].registerid);


        } else {
          toast.error(res["data"].message || "Error");
        }
        setLoader(false);
      })
      .catch((error) => {
        toast.error("An error occurred while submitting the form.");
        setLoader(false);
      });
  };

  // const handleFormSubmit = (e) => {
  //   e.preventDefault(); 

  //   const form = e.currentTarget;

  //   if (form.checkValidity() === false) {
  //     e.stopPropagation();
  //     setValidated(true);
  //     return;
  //   }

  //   setLoader(true);
  //   const formData = new FormData(form);

  //   specifications.forEach((spec, index) => {
  //     formData.append(`specifications[${index}][key]`, spec.key);
  //     formData.append(`specifications[${index}][value]`, spec.value);
  //     formData.append(`specifications[${index}][dob]`, spec.dob);
  //     formData.append(`specifications[${index}][class]`, spec.class);
  //     formData.append(`specifications[${index}][competition]`, spec.competition);
  //     formData.append(`specifications[${index}][category]`, spec.category);
  //     formData.append(`specifications[${index}][sub_category]`, spec.sub_category);
  //   });

  //   axios
  //     .post(ServiceUrl.IFORM.SAVE_MEMBER_REGISTRATION, formData)
  //     .then((res) => {
  //       if (res["data"].status == "1") {
  //         setEditorContent("");
  //         setRegisterid(res["data"].registerid);
  //         toast.success(res["data"].message || "Success");
  //       } else {
  //         toast.error(res["data"].message || "Error");
  //       }
  //       setLoader(false);
  //     })
  //     .catch((error) => {
  //       toast.error("An error occurred while submitting the form.");
  //       setLoader(false);
  //     });
  // };


  const handleTypeOfInstitutionChange = (e) => {
    const selectedInstitutionType = e.target.value;
    setTypeOfInstitution(selectedInstitutionType);
    console.log('selectedInstitutionType', selectedInstitutionType);

    const filteredCategories = category.filter(item => item.instution_type == selectedInstitutionType);
    setFilteredCategories(filteredCategories);
    console.log(filteredCategories);
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);

    const filtered = subcategory.filter((item) => item.category_id === categoryId);
    setFilteredSubcategories(filtered);
  };

  console.log('registerId', registerId);

  const downloadFile = (temp_id) => {

    const query = `${registerId}`;
    const link = `${baseUrl}mob/v1/registrationform?q=${query}&temp_id=${temp_id}`;
    var a = document.createElement("a");
    a.setAttribute("download", registerId);
    a.setAttribute("href", link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleButtonClick = () => {
    setModalVisible(true);

  };

  const handleOk = () => {
    if (selectedValue) {
      const tempData = template.find((item) => item.id === selectedValue);
      if (tempData) {
        downloadFile(tempData.id);
      }
      setModalVisible(false);
    }
  };


  const handleCancel = () => {
    setModalVisible(false);
  };


  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <>
      <div className="container py-3">
       
          <div
            className="top-bar row gx-0 align-items-center d-lg-flex"
            style={{
              background: "#011a41",
              color: "#fff",
              height: "42px",
              verticalAlign: "middle",
            }}
          >
            <div className="col-lg-6 px-5 text-start"></div>
            <div className="col-lg-6 px-5 text-end"></div>
          </div>
          <nav
            className="navbar navbar-expand-lg navbar-light py-lg-0 px-lg-5 wow fadeIn"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeIn",
            }}
          >
            <a href="#" className="navbar-brand ms-4 ms-lg-0 text-center">
              <img
                src={VENDOR_LOGO}
                //className="bg-white rounded-3 mb-3"
                style={{ width: "120px" }}
              />
            </a>
            <div
              className="font-bold mb-3 header_company_title"
              style={{ fontSize: "x-large",color: "black"  }}
            >
              {upperCase(context.settingValue("print_header_name")) || appTittle}
            </div>
          </nav>
          <Row
            className=" p-4 p-lg-0"
            style={{
              textAlign: "center",
              backgroundColor: "rgb(250, 174, 66)",
              height: "50px",
              verticalAlign: "middle",
            }}
          >
            <Col md={12}>
              <label className="fw-bold" style={{ textAlign: "center", fontSize: "2rem" }}>
                MATH EXPO 
              </label>
            </Col>
          </Row>
        <Card.Header>
          <Row>
            <Col md={10}></Col>
            <Col md={2}>
              {registerId && (
                <Button size="sm" variant="white" onClick={(e) => handleButtonClick()}
                >
                  <i className="fa fa-download fs-6"></i> Download Pdf
                </Button>
              )}
            </Col>
          </Row>
        </Card.Header>
        <Spin spinning={loader}>
          <Form
            method="post"
            noValidate
            validated={validated}
            id="frm_saveCourse"
            onSubmit={handleFormSubmit}
          >
            <Row className="mt-2">
              <Col md={12}>
                <label>
                  Type Of Institution <span className="text-danger">*</span>
                </label>
                <Form.Control
                  as="select"
                  size="sm"
                  className="fw-bold form-select form-select-sm"
                  name="type_of_institution"
                  value={typeOfInstitution}
                  onChange={handleTypeOfInstitutionChange}
                  required
                >
                  <option value="select"> Select </option>
                  <option value="school"> School </option>
                  <option value="college"> College </option>
                </Form.Control>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <label>
                  Name of the Institution <span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="text"
                  name="name_of_institution"
                  className="fw-bold"
                  size="sm"
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <label>
                  Address
                </label>
                <Form.Control
                  type="text"
                  name="address"
                  className="fw-bold"
                  size="sm"

                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <label>
                  District
                </label>
                <Form.Control
                  type="text"
                  name="district"
                  className="fw-bold"
                  size="sm"

                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <label>
                  Contact Staff <span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="text"
                  name="contact_staff"
                  className="fw-bold"
                  size="sm"
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <label>
                  Contact Staff Mobile Number <span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="mobile"
                  name="contact_staff_mobile_number"
                  className="fw-bold"
                  size="sm"
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={handleAddSpecification}
                >
                  Add Student
                </Button>
              </Col>
            </Row>
            {specifications.map((spec, index) => (
              <Row key={index} className="mt-2">
                <Col md={2}>
                  <label>Name</label>
                  <Form.Control
                    type="text"
                    placeholder="Student Name"
                    size="sm"
                    value={spec.key}
                    onChange={(e) =>
                      handleSpecificationChange(index, "key", e.target.value)
                    }
                  />
                </Col>
                <Col md={1}>
                  <label>Gender</label>
                  <Form.Select
                    size="sm"
                    value={spec.value}
                    onChange={(e) =>
                      handleSpecificationChange(index, "value", e.target.value)
                    }
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Form.Select>
                </Col>
                <Col md={2}>
                  <label>Dob</label>
                  <Form.Control
                    type="date"
                    placeholder="Date of Birth"
                    size="sm"
                    value={spec.dob}
                    onChange={(e) =>
                      handleSpecificationChange(index, "dob", e.target.value)
                    }
                  />
                </Col>
                <Col md={2}>
                  <label>Class / Course</label>
                  <Form.Control
                    type="text"
                    placeholder="Class"
                    size="sm"
                    value={spec.class}
                    onChange={(e) =>
                      handleSpecificationChange(index, "class", e.target.value)
                    }
                  />
                </Col>
                <>
                  <Col md={2}>
                    <label className="fs-sm">Category</label>
                    <Form.Control
                      as="select"
                      className="fw-bold form-select form-select-sm"
                      size="sm"
                      name={`category-${index}`}
                      value={spec.category || ""}
                      onChange={(e) =>
                        handleSpecificationChange(index, "category", e.target.value)
                      }
                    >
                      <option value="">-Select-</option>
                      {filteredCategories.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.category_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col md={2}>
                    <label className="fs-sm">Sub Category</label>
                    <Form.Control
                      as="select"
                      className="fw-bold form-select form-select-sm"
                      size="sm"
                      name={`sub_category-${index}`}
                      disabled={!spec.category}
                      value={spec.sub_category || ""}
                      onChange={(e) =>
                        handleSpecificationChange(index, "sub_category", e.target.value)
                      }
                    >
                      <option value="">-Select-</option>
                      {spec.subcategories && spec.subcategories.length > 0 ? (
                        spec.subcategories.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.subcategory_name}
                          </option>
                        ))
                      ) : (
                        <option>No subcategories available</option>
                      )}
                    </Form.Control>
                  </Col>
                </>
                <Col md={1}>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => handleRemoveSpecification(index)}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            ))}

            <Row className="mt-3">
              <Col md={12}>
                <div className="text-end">
                  <Button type="submit" size="sm">
                    <i className="fa-solid fa-check me-2"></i>Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>

      <Modal
        title="Choose Template"
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          value={selectedValue}
          onChange={handleSelectChange}
          style={{ width: "100%" }}
          placeholder="Select"
        >
          {template.map((item) => (
            <Select.Option value={item.id} key={item.id}>
              {upperCase(item.certificate_name)}
            </Select.Option>
          ))}
        </Select>
      </Modal>

    </>

  );
};

export default EventRegistration;
